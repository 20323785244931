import request from './_config'

const path = `ads`

export function createAd(tiktok_account_id, data) {
    return request({
        url: `${path}/${tiktok_account_id}`,
        method: 'post',
        data: {
            belong_to_atosa: data.belong_to_atosa,
            adgroup_id: data.adgroup_id,
            advertiser_id: data.advertiser_id,
            creatives: data.creatives
        }
    })
}

export function getAds(tiktok_account_id, params) {
    return request({
        url: `${path}/${tiktok_account_id}`,
        method: 'get',
        params: {
            advertiser_id: params.advertiser_id,
            filtering: params.filtering
        }
    })
}

export function updateAd(tiktok_account_id, data) {
    return request({
        url: `${path}/${tiktok_account_id}`,
        method: 'put',
        data
    })
}

export function updateAdStatus(tiktok_account_id, data) {
    return request({
        url: `${path}/status/${tiktok_account_id}`,
        method: 'post',
        data: {
            advertiser_id: data.advertiser_id,
            ad_ids: data.ad_ids,
            belong_to_atosa: data.belong_to_atosa,
            operation_status: data.operation_status
        }
    })
}
