import i18n from '@/plugins/language'

export const formatAge = (age) => {
    if (age === 'NONE') {
        return i18n.t('common.unknown')
    }
    const arr = age.split('_')
    if (arr[2] >= 90) {
        return `>=${arr[1]}`
    }
    return `${arr[1]}-${arr[2]}`
}
