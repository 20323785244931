import {
    getReportCampaigns,
    getSyncReport,
    exportExcel
} from '@/services/atosa-tiktok-ads/reporting'
import paginationDataMixin from '@/plugins/mixins/pagination-data'
import moment from 'moment'
import { updateCampaignStatus } from '@/services/atosa-tiktok-ads/campaign'
import { updateAdStatus } from '@/services/atosa-tiktok-ads/ad'
import { updateAdgroupStatus } from '@/services/atosa-tiktok-ads/adgroup'

export default {
    mixins: [paginationDataMixin],

    props: [
        'switch_compare',
        'name_filtering',
        'filtering',
        'multiple_selection',
        'handle_select_row',
        'current_package',
        'type',
        'currency',
        'tiktok_account_id',
        'compare_ranger_date',
        'ranger_date',
        'belong_to_atosa',
        'has_auto_ads',
        'has_manager_ads',
        'has_report_ads',
        'current_balance',
        'advertiser_id'
    ],

    data() {
        return {
            m__type_statistical_detail: 'day',
            m__show_config_auto_refresh_dialog: false,
            m__auto_refresh_config: {
                auto_refresh: false,
                seconds: 20,
                never_stop: true,
                stop_after_minutes: 1
            },
            m__filtering: [],
            m__type: '',
            m__order_field: '',
            m__order_type: 'DESC', // DESC , ASC
            m__performance_column_values: [],
            polling: null,
            timeout: null,
            total_metrics: null,
            compare_total_metrics: null,
            data_campaign_report_get: []
        }
    },

    computed: {
        m__selected_columns() {
            const temp = []
            this.m__performance_column_values.forEach((item) => {
                temp.push(...item.child_values)
            })
            return temp.length
                ? temp
                : [
                    'spend',
                    'cpc',
                    'cpm',
                    'impressions',
                    'clicks',
                    'ctr',
                    'conversion',
                    'cost_per_conversion',
                    'conversion_rate'
                ]
        }
    },

    watch: {
        async multiple_selection_inline() {
            this.fetchTotalMetrics()
        },

        m__type_statistical_detail() {
            this.m__page_info.page = 1
            this.m__getReportCampaignsByAccount()
        },

        m__performance_column_values() {
            this.m__page_info.page = 1
            this.m__getReportCampaignsByAccount()
        },

        advertiser_id() {
            this.m__page_info.page = 1
            this.m__getReportCampaignsByAccount()
        },

        ranger_date() {
            this.m__page_info.page = 1
            this.m__getReportCampaignsByAccount()
        },

        compare_ranger_date() {
            this.m__page_info.page = 1
            this.m__getReportCampaignsByAccount()
        },

        m__selected_columns() {
            localStorage.setItem(
                `performance_column_values_${this.m__type}`,
                JSON.stringify(this.m__performance_column_values)
            )

            this.m__page_info.page = 1
            this.m__getReportCampaignsByAccount()
        },

        filtering() {
            this.m__page_info.page = 1
            this.m__filtering = [...this.filtering]
            this.m__getReportCampaignsByAccount()
        },

        name_filtering() {
            this.m__page_info.page = 1
            this.m__getReportCampaignsByAccount()
        },

        type() {
            if (this.m__type !== this.type) {
                clearInterval(this.polling)
            } else {
                this.m__handleToggleAutoRefresh()
            }
        }
    },

    methods: {
        getFiltering() {
            let filtering = [...this.m__filtering]

            if (this.m__type === 'campaign_id') {
                filtering = filtering.filter((item) =>
                    ['adgroup_status', 'ad_status'].every(
                        (i) => i !== item.field_name
                    )
                )
            } else if (this.m__type === 'adgroup_id') {
                filtering = filtering.filter((item) =>
                    ['campaign_status', 'ad_status'].every(
                        (i) => i !== item.field_name
                    )
                )

                if (this.multiple_selection_campaign.length === 0) {
                    filtering.push({
                        field_name: 'campaign_ids',
                        filter_type: 'IN',
                        filter_value: JSON.stringify(this.filter_campaign_ids)
                    })
                }
            } else if (this.m__type === 'ad_id') {
                filtering = filtering.filter((item) =>
                    ['campaign_status', 'adgroup_status'].every(
                        (i) => i !== item.field_name
                    )
                )

                if (this.multiple_selection_adgroup.length === 0) {
                    filtering.push({
                        field_name: 'adgroup_ids',
                        filter_type: 'IN',
                        filter_value: JSON.stringify(this.filter_adgroup_ids)
                    })
                }
            }

            return filtering
        },

        async fetchTotalMetrics() {
            let filtering_total_metrics = []

            if (this.m__type === 'campaign_id') {
                filtering_total_metrics = [
                    {
                        field_name: 'campaign_ids',
                        filter_type: 'IN',
                        filter_value: JSON.stringify(
                            this.multiple_selection_inline.length
                                ? this.multiple_selection_inline.map(
                                    (item) => item.campaign.campaign_id
                                )
                                : this.m__data_for_pagination.map(
                                    (item) => item.campaign.campaign_id
                                )
                        )
                    }
                ]
            } else if (this.m__type === 'adgroup_id') {
                filtering_total_metrics = [
                    {
                        field_name: 'adgroup_ids',
                        filter_type: 'IN',
                        filter_value: JSON.stringify(
                            this.multiple_selection_inline.length
                                ? this.multiple_selection_inline.map(
                                    (item) => item.adgroup.adgroup_id
                                )
                                : this.m__data_for_pagination.map(
                                    (item) => item.adgroup.adgroup_id
                                )
                        )
                    }
                ]
            } else {
                filtering_total_metrics = [
                    {
                        field_name: 'ad_ids',
                        filter_type: 'IN',
                        filter_value: JSON.stringify(
                            this.multiple_selection_inline.length
                                ? this.multiple_selection_inline.map(
                                    (item) => item.ad.ad_id
                                )
                                : this.m__data_for_pagination.map(
                                    (item) => item.ad.ad_id
                                )
                        )
                    }
                ]
            }

            this.total_metrics = await this.getTotalMetrics(
                filtering_total_metrics,
                this.ranger_date
            )

            if (this.compare_ranger_date) {
                this.compare_total_metrics = await this.getTotalMetrics(
                    filtering_total_metrics,
                    this.compare_ranger_date
                )
            }
        },

        m__getContentWidthColumn(column) {
            if (
                ['result', 'cost_per_result', 'result_rate'].some(
                    (item) => item === column
                )
            ) {
                return `160px`
            }
            return '120px'
        },

        async m__handleExportExcel() {
            if (!this.m__data_for_pagination.length) {
                return
            }

            let headers = this.m__selected_columns.map((column) =>
                this.$t(`page.campaign.column_${column}`)
            )
            let body = []
            let name = ''

            if (this.m__type === 'campaign_id') {
                headers = [
                    'ID',
                    this.$t('common.campaign'),
                    this.$t('page.campaign.objective_type'),
                    ...headers
                ]

                body = this.m__data_for_pagination.map((item) => {
                    return [
                        item.id,
                        item.campaign.campaign_name,
                        item.campaign.objective_type,
                        ...this.m__selected_columns.map((column) =>
                            this.m__formatterColumn(
                                item[column],
                                item,
                                item.currency
                            )
                        )
                    ]
                })

                name = 'excel_campaign'
            } else if (this.m__type === 'adgroup_id') {
                headers = [
                    'ID',
                    this.$t('common.name'),
                    this.$t('common.budget'),
                    ...headers
                ]

                body = this.m__data_for_pagination.map((item) => {
                    return [
                        item.id,
                        item.adgroup.adgroup_name,
                        ...this.m__selected_columns.map((column) =>
                            this.m__formatterColumn(
                                item[column],
                                item,
                                item.currency
                            )
                        )
                    ]
                })

                name = 'excel_adgroup'
            } else if (this.m__type === 'ad_id') {
                headers = ['ID', this.$t('common.name'), ...headers]

                body = this.m__data_for_pagination.map((item) => {
                    return [
                        item.id,
                        item.ad.ad_name,
                        ...this.m__selected_columns.map((column) =>
                            this.m__formatterColumn(
                                item[column],
                                item,
                                item.currency
                            )
                        )
                    ]
                })

                name = 'excel_ad'
            }

            const response = await exportExcel({
                name,
                headers,
                body
            })
            const date = moment().format('HHmmDDMMyyyy')

            const filename = `excel-${date}`
            this.p__exportExcelByBlob(response, filename)
        },

        m__showColumn(item_list) {
            this.m__performance_column_values = item_list
        },

        async m__getReportCampaignsByAccount() {
            if (
                !this.m__type ||
                !this.advertiser_id ||
                !this.tiktok_account_id
            ) {
                return
            }

            if (
                this.m__type_statistical_detail !== 'none' &&
                this.m__data_for_pagination.length > 0
            ) {
                this.m__data_for_pagination = []
            }

            this.m__loading = true

            try {
                const filtering = this.getFiltering()

                let temp = {
                    order_field: this.m__order_field,
                    order_type: this.m__order_type,
                    page_size: this.m__page_info.page_size,
                    page: this.m__page_info.page,
                    advertiser_id: this.advertiser_id,
                    type: this.m__type,
                    report_type: 'BASIC',
                    start_date: this.ranger_date[0],
                    end_date: this.ranger_date[1],
                    filtering: JSON.stringify(filtering),
                    metrics: JSON.stringify(this.m__selected_columns)
                }

                if (this.name_filtering) {
                    temp = {
                        ...temp,
                        name_filtering: this.name_filtering
                    }
                }

                const response = await getReportCampaigns(
                    this.tiktok_account_id,
                    temp
                )

                if (response.code === 0) {
                    if (
                        this.m__type === 'campaign_id' &&
                        filtering.some(
                            (fil) => fil.field_name === 'campaign_status'
                        )
                    ) {
                        this.p__event_bus.$emit(
                            'eb_get_filter_campaign_ids',
                            response.data.list.map(
                                (item) => item.campaign.campaign_id
                            )
                        )
                    } else if (
                        this.m__type === 'adgroup_id' &&
                        filtering.some(
                            (fil) =>
                                fil.field_name === 'adgroup_status' ||
                                fil.field_name === 'adgroup_ids'
                        )
                    ) {
                        this.p__event_bus.$emit(
                            'eb_get_filter_adgroup_ids',
                            response.data.list.map(
                                (item) => item.adgroup.adgroup_id
                            )
                        )
                    }

                    this.m__data_for_pagination = response.data.list

                    this.fetchTotalMetrics()

                    this.m__page_info.total_number =
                        response.data.page_info.total_number
                    this.m__page_info.total_page =
                        response.data.page_info.total_page

                    if (this.compare_ranger_date) {
                        let temp = {
                            order_field: this.m__order_field,
                            order_type: this.m__order_type,
                            page_size: this.m__page_info.page_size,
                            page: this.m__page_info.page,
                            advertiser_id: this.advertiser_id,
                            type: this.m__type,
                            report_type: 'BASIC',
                            start_date: this.compare_ranger_date[0],
                            end_date: this.compare_ranger_date[1],
                            filtering: JSON.stringify(filtering),
                            metrics: JSON.stringify(this.m__selected_columns)
                        }

                        if (this.name_filtering) {
                            temp = {
                                ...temp,
                                name_filtering: this.name_filtering
                            }
                        }

                        const response_compare_range_date =
                            await getReportCampaigns(
                                this.tiktok_account_id,
                                temp
                            )

                        const list_compare =
                            response_compare_range_date.data.list

                        this.m__data_for_pagination = [
                            ...response.data.list
                        ].map((item, index) => {
                            return {
                                ...item,
                                _compare: list_compare[index]
                            }
                        })
                    }

                    if (this.m__type === 'campaign_id') {
                        this.fetchConfigAds()
                    } else if (this.m__type === 'adgroup_id') {
                        this.m__data_for_pagination =
                            this.m__data_for_pagination.map((item) => {
                                const schedule_start_time = moment()
                                    .add(1, 'days')
                                    .format(this.p__format_schedule_tiktok_time)

                                const schedule_end_time = moment()
                                    .add(31, 'days')
                                    .format(this.p__format_schedule_tiktok_time)

                                return {
                                    ...item,
                                    schedule_start_time,
                                    schedule_end_time
                                }
                            })
                    }

                    if (this.m__type_statistical_detail !== 'none') {
                        this.m__data_for_pagination =
                            this.m__data_for_pagination.map((item) => ({
                                ...item,
                                _has_children: true
                            }))
                    }
                }
            } catch (error) {
                console.error(error)
            }

            this.m__loading = false
        },

        async m__getReportCampaignsByAccountCopy() {
            try {
                const filtering = this.getFiltering()

                let temp = {
                    order_field: this.m__order_field,
                    order_type: this.m__order_type,
                    page_size: this.m__page_info.page_size,
                    page: this.m__page_info.page,
                    advertiser_id: this.advertiser_id,
                    type: this.m__type,
                    report_type: 'BASIC',
                    start_date: this.ranger_date[0],
                    end_date: this.ranger_date[1],
                    filtering: JSON.stringify(filtering),
                    metrics: JSON.stringify(this.m__selected_columns)
                }

                if (this.name_filtering) {
                    temp = {
                        ...temp,
                        name_filtering: this.name_filtering
                    }
                }

                const response = await getReportCampaigns(
                    this.tiktok_account_id,
                    temp
                )

                if (response.code === 0) {
                    if (
                        this.m__type === 'campaign_id' &&
                        filtering.some(
                            (fil) => fil.field_name === 'campaign_status'
                        )
                    ) {
                        this.p__event_bus.$emit(
                            'eb_get_filter_campaign_ids',
                            response.data.list.map(
                                (item) => item.campaign.campaign_id
                            )
                        )
                    } else if (
                        this.m__type === 'adgroup_id' &&
                        filtering.some(
                            (fil) =>
                                fil.field_name === 'adgroup_status' ||
                                fil.field_name === 'adgroup_ids'
                        )
                    ) {
                        this.p__event_bus.$emit(
                            'eb_get_filter_adgroup_ids',
                            response.data.list.map(
                                (item) => item.adgroup.adgroup_id
                            )
                        )
                    }
                    this.data_campaign_report_get = response.data.list
                }
            } catch (error) {
                console.error(error)
            }
        },

        async getTotalMetrics(filtering, ranger_date) {
            let data_level = 'AUCTION_CAMPAIGN'

            if (this.m__type === 'adgroup_id') {
                data_level = 'AUCTION_ADGROUP'
            } else if (this.m__type === 'ad_id') {
                data_level = 'AUCTION_AD'
            }

            const temp_response = await getSyncReport(this.tiktok_account_id, {
                page: 1,
                page_size: 1,
                report_type: 'BASIC',
                data_level,
                dimensions: [this.m__type],
                advertiser_id: this.advertiser_id,
                type: this.m__type,
                order_field: this.m__order_field,
                order_type: this.m__order_type,
                start_date: ranger_date[0],
                end_date: ranger_date[1],
                enable_total_metrics: true,
                filtering: JSON.stringify(filtering),
                metrics: JSON.stringify(this.m__selected_columns)
            })

            if (
                temp_response.code === 0 &&
                temp_response.data.list.length > 0
            ) {
                return temp_response.data.total_metrics
            }

            return null
        },

        m__formatterColumn(value, column_property, currency) {
            if (
                [
                    'cpc',
                    'cpm',
                    'spend',
                    'cost_per_conversion',
                    'cost_per_result',
                    'real_time_cost_per_conversion',
                    'real_time_cost_per_result',
                    'cost_per_1000_reached',
                    'bid_price',
                    'conversion_bid_price',
                    'total_onsite_shopping_value',
                    'adgroup.conversion_bid_price'
                ].some((item) => item === column_property)
            ) {
                return this.p__renderMoneyByCurrency(value, currency)
            }

            if (
                [
                    'impressions',
                    'clicks',
                    'conversion',
                    'onsite_on_web_detail',
                    'live_views',
                    'video_play_actions'
                ].some((item) => item === column_property)
            ) {
                return this.p__formatNumberHasCommas(value)
            }

            if (
                [
                    'ctr',
                    'result_rate',
                    'conversion_rate',
                    'real_time_conversion_rate',
                    'real_time_result_rate',
                    'profile_visits_rate',
                    'anchor_click_rate',
                    'ix_page_viewrate_avg',
                    'engagement_rate'
                ].some((item) => item === column_property)
            ) {
                return `${value}%`
            }

            return value
        },

        async fetchReportCampaigns(id, start_date, end_date) {
            let field_name = 'campaign_ids'
            if (this.m__type === 'campaign_id') {
                field_name = 'campaign_ids'
            } else if (this.m__type === 'adgroup_id') {
                field_name = 'adgroup_ids'
            } else if (this.m__type === 'ad_id') {
                field_name = 'ad_ids'
            }

            const filtering = {
                filter_value: JSON.stringify([id]),
                field_name,
                filter_type: 'IN'
            }

            const temp = {
                order_field: this.m__order_field,
                order_type: this.m__order_type,
                page_size: this.m__page_info.page_size,
                page: this.m__page_info.page,
                advertiser_id: this.advertiser_id,
                type: this.m__type,
                report_type: 'BASIC',
                start_date,
                end_date,
                filtering: JSON.stringify([filtering]),
                metrics: JSON.stringify(this.m__selected_columns)
            }

            const response = await getReportCampaigns(
                this.tiktok_account_id,
                temp
            )

            return response
        },

        async m__handleLoadExpand(tree, treeNode, resolve) {
            let range_date_list = []

            const start_date = this.ranger_date[0]
            const end_date = this.ranger_date[1]

            if (this.m__type_statistical_detail === 'day') {
                range_date_list = this.p__getArr30days(this.ranger_date)
            } else if (this.m__type_statistical_detail === 'week') {
                const startDate = moment(start_date)
                const endDate = moment(end_date)

                const currentWeekStart = startDate.clone().startOf('week')

                while (currentWeekStart.isSameOrBefore(endDate, 'day')) {
                    const currentWeekEnd = currentWeekStart
                        .clone()
                        .endOf('week')
                    const end = currentWeekEnd.isBefore(endDate)
                        ? currentWeekEnd
                        : endDate

                    range_date_list.push([
                        currentWeekStart.format('YYYY-MM-DD'),
                        end.format('YYYY-MM-DD')
                    ])

                    currentWeekStart.add(1, 'week').startOf('week')
                }
            } else if (this.m__type_statistical_detail === 'month') {
                const startDate = moment(start_date)
                const endDate = moment(end_date)

                const currentMonthStart = startDate.clone().startOf('month')

                while (currentMonthStart.isSameOrBefore(endDate, 'month')) {
                    const currentMonthEnd = currentMonthStart
                        .clone()
                        .endOf('month')
                    const end = currentMonthEnd.isBefore(endDate)
                        ? currentMonthEnd
                        : endDate

                    range_date_list.push([
                        currentMonthStart.format('YYYY-MM-DD'),
                        end.format('YYYY-MM-DD')
                    ])

                    currentMonthStart.add(1, 'month').startOf('month')
                }
            }

            let children = []

            if (this.m__type_statistical_detail === 'day') {
                const temp = []

                await Promise.all(
                    range_date_list.map(async (new_ranger_date) => {
                        const start_date = new_ranger_date[0]
                        const end_date = new_ranger_date[1]

                        let field_name = 'campaign_ids'
                        let data_level = 'AUCTION_CAMPAIGN'

                        if (this.m__type === 'campaign_id') {
                            field_name = 'campaign_ids'
                        } else if (this.m__type === 'adgroup_id') {
                            field_name = 'adgroup_ids'
                            data_level = 'AUCTION_ADGROUP'
                        } else if (this.m__type === 'ad_id') {
                            field_name = 'ad_ids'
                            data_level = 'AUCTION_AD'
                        }

                        const filtering = {
                            filter_value: JSON.stringify([tree.id]),
                            field_name,
                            filter_type: 'IN'
                        }

                        const response = await getSyncReport(
                            this.tiktok_account_id,
                            {
                                page: 1,
                                page_size: 40,
                                report_type: 'BASIC',
                                data_level,
                                metrics: JSON.stringify(
                                    this.m__selected_columns
                                ),
                                start_date,
                                end_date,
                                dimensions: ['stat_time_day'],
                                order_field: this.m__order_field,
                                order_type: this.m__order_type,
                                advertiser_id: this.advertiser_id,
                                type: this.m__type,
                                filtering: JSON.stringify([filtering])
                            }
                        )
                        if (
                            response.code === 0 &&
                            response.data.list.length > 0
                        ) {
                            temp.push(
                                ...response.data.list.map((item) => ({
                                    ...item.metrics,
                                    child_name: moment(
                                        item.dimensions.stat_time_day
                                    ),
                                    id: this.p__renderId(5),
                                    is_child: true,
                                    index: 0
                                }))
                            )
                        }
                    })
                )

                temp.sort((a, b) =>
                    moment(b.child_name).diff(moment(a.child_name))
                )
                children = temp.map((item) => ({
                    ...item,
                    child_name: moment(item.child_name).format(
                        this.$t('common.format_date')
                    )
                }))
            } else {
                await Promise.all(
                    range_date_list.map(async (range_date, index) => {
                        let st = range_date[0]
                        let et = range_date[0]

                        st = range_date[0]
                        et = range_date[1]

                        const response = await this.fetchReportCampaigns(
                            tree.id,
                            st,
                            et
                        )

                        let name = ''

                        const start_date = moment(
                            range_date[0],
                            'YYYY-MM-DD'
                        ).format(
                            this.$t('common.format_value_date_picker_moment')
                        )

                        const end_date = moment(
                            range_date[1],
                            'YYYY-MM-DD'
                        ).format(
                            this.$t('common.format_value_date_picker_moment')
                        )

                        name = `${start_date} ~ ${end_date}`

                        children.push(
                            ...response.data.list.map((item) => ({
                                ...item,
                                child_name: name,
                                id: this.p__renderId(5),
                                is_child: true,
                                index
                            }))
                        )
                    })
                )

                children.sort(function (a, b) {
                    return b.index - a.index
                })
            }

            const m__formatViewMetrics = (total, column_property, currency) => {
                if (
                    [
                        'cpc',
                        'cpm',
                        'cost_per_conversion',
                        'real_time_cost_per_conversion',
                        'cost_per_1000_reached',
                        'spend',
                        'total_onsite_shopping_value'
                    ].some((item) => item === column_property)
                ) {
                    return this.p__renderMoneyByCurrency(total, currency)
                }

                if (
                    [
                        'ctr',
                        'conversion_rate',
                        'real_time_conversion_rate',
                        'frequency'
                    ].some((item) => item === column_property)
                ) {
                    return `${(+total).toFixed(2)}`
                }

                if (
                    [
                        'result',
                        'cost_per_result',
                        'real_time_result',
                        'result_rate',
                        'real_time_cost_per_result',
                        'real_time_result_rate',
                        'impressions',
                        'clicks',
                        'conversion',
                        'onsite_on_web_detail',
                        'live_views',
                        'video_play_actions'
                    ].some((item) => item === column_property)
                ) {
                    return this.p__formatNumberHasCommas(total)
                }

                return total
            }

            children = children.map((item) => {
                let temp = {}
                const currency = this.currency
                Object.keys(item).forEach(function (key, idx) {
                    const new_value = m__formatViewMetrics(
                        item[key],
                        key,
                        currency
                    )
                    temp = {
                        ...temp,
                        [key]: new_value
                    }
                })

                return temp
            })

            if (children.length > 0) {
                resolve(children)
            } else {
                resolve([])
            }
        },

        async m__handleMassTurnOn() {
            if (!this.multiple_selection_inline.length) {
                return
            }

            try {
                if (this.m__type === 'campaign_id') {
                    await Promise.all(
                        this.multiple_selection_inline.map(async (item) => {
                            await updateCampaignStatus(this.tiktok_account_id, {
                                advertiser_id: this.advertiser_id,
                                campaign_ids: [item.campaign.campaign_id],
                                belong_to_atosa: this.belong_to_atosa,
                                operation_status: 'ENABLE'
                            })
                        })
                    )
                    this.m__getReportCampaignsByAccount()
                } else if (this.m__type === 'adgroup_id') {
                    await Promise.all(
                        this.multiple_selection_inline.map(async (item) => {
                            await updateAdgroupStatus(this.tiktok_account_id, {
                                advertiser_id: this.advertiser_id,
                                adgroup_ids: [item.adgroup.adgroup_id],
                                belong_to_atosa: this.belong_to_atosa,
                                operation_status: 'ENABLE'
                            })
                        })
                    )

                    this.m__getReportCampaignsByAccount()
                } else if (this.m__type === 'ad_id') {
                    await Promise.all(
                        this.multiple_selection_inline.map(async (item) => {
                            await updateAdStatus(this.tiktok_account_id, {
                                advertiser_id: this.advertiser_id,
                                ad_ids: [item.ad.ad_id],
                                belong_to_atosa: this.belong_to_atosa,
                                operation_status: 'ENABLE'
                            })
                        })
                    )
                }

                this.m__getReportCampaignsByAccount()

                this.p__showNotify(
                    'success',
                    this.$t('page.campaign.message_update_mess_status_success')
                )
            } catch (error) {
                console.error(error)
            }
        },

        async m__handleMassTurnOff() {
            if (!this.multiple_selection_inline.length) {
                return
            }

            try {
                if (this.m__type === 'campaign_id') {
                    await Promise.all(
                        this.multiple_selection_inline.map(async (item) => {
                            await updateCampaignStatus(this.tiktok_account_id, {
                                advertiser_id: this.advertiser_id,
                                campaign_ids: [item.campaign.campaign_id],
                                belong_to_atosa: this.belong_to_atosa,
                                operation_status: 'DISABLE'
                            })
                        })
                    )
                } else if (this.m__type === 'adgroup_id') {
                    await Promise.all(
                        this.multiple_selection_inline.map(async (item) => {
                            await updateAdgroupStatus(this.tiktok_account_id, {
                                advertiser_id: this.advertiser_id,
                                adgroup_ids: [item.adgroup.adgroup_id],
                                belong_to_atosa: this.belong_to_atosa,
                                operation_status: 'DISABLE'
                            })
                        })
                    )
                } else if (this.m__type === 'ad_id') {
                    await Promise.all(
                        this.multiple_selection_inline.map(async (item) => {
                            await updateAdStatus(this.tiktok_account_id, {
                                advertiser_id: this.advertiser_id,
                                ad_ids: [item.ad.ad_id],
                                belong_to_atosa: this.belong_to_atosa,
                                operation_status: 'DISABLE'
                            })
                        })
                    )
                }

                this.m__getReportCampaignsByAccount()

                this.p__showNotify(
                    'success',
                    this.$t('page.campaign.message_update_mess_status_success')
                )
            } catch (error) {
                console.error(error)
            }
        },

        m__getSummaries(param) {
            const { columns, data } = param
            const sums = []
            columns.forEach((column, index) => {
                const { property } = column
                if (
                    (this.m__type_statistical_detail === 'none' &&
                        index === 2) ||
                    (this.m__type_statistical_detail !== 'none' && index === 3)
                ) {
                    let name_translate = 'campaign'
                    if (this.m__type === 'adgroup_id') {
                        name_translate = 'adgroup'
                    } else if (this.m__type === 'ad_id') {
                        name_translate = 'ad'
                    }

                    sums[index] = `${this.$t('common.total')} \n ${this.m__page_info.total_number
                    } ${this.$t('common.xxx_has_plural_noun', {
                        xxx: this.$t(`common.${name_translate}`)
                    })}`
                    return
                }

                const values = data.map((item) => {
                    if (property) {
                        const pathArray = property.split('.')
                        if (pathArray.length > 1) {
                            let value = item
                            for (const key of pathArray) {
                                value = value[key]
                            }

                            return Number(value)
                        }
                    }

                    return Number(item[property])
                })

                if (!values.every((value) => isNaN(value))) {
                    let total = this.calculateValue(
                        values,
                        property,
                        data[0].currency,
                        this.total_metrics
                    )
                    if (
                        this.compare_ranger_date &&
                        this.switch_compare &&
                        data.every((item) => item._compare)
                    ) {
                        const compare_values = data.map((item) =>
                            Number(item[`_compare`][column.property])
                        )
                        if (!compare_values.every((value) => isNaN(value))) {
                            const total_calculate = this.calculateValue(
                                compare_values,
                                column.property,
                                data[0].currency,
                                this.compare_total_metrics
                            )

                            total += `\n ${total_calculate}`
                        }
                    }

                    if (property.includes('budget')) {
                        // const extend = ''
                        let extend = ''
                        if (this.multiple_selection_inline.length) {
                            let budget = 0

                            this.multiple_selection_inline.forEach((item) => {
                                const pathArray = property.split('.')
                                if (pathArray.length > 1) {
                                    let value = item
                                    for (const key of pathArray) {
                                        value = value[key]
                                    }

                                    budget += Number(value)
                                }
                            })
                            if (budget > 0) {
                                extend = `${this.p__renderMoneyByCurrency(
                                    budget,
                                    data[0].currency
                                )}\n`
                            }
                        }
                        sums[index] = `${extend}${total}`
                    } else {
                        sums[index] = total
                    }
                } else {
                    sums[index] = ''
                }
            })

            return sums
        },

        calculateValue(values, column_property, currency, total_metrics) {
            if (
                ['campaign.budget', 'adgroup.budget', 'ad.budget'].some(
                    (item) => column_property === item
                )
            ) {
                const total = values
                    .reduce((prev, curr) => {
                        const value = Number(curr)
                        if (!isNaN(value)) {
                            return prev + curr
                        } else {
                            return prev
                        }
                    }, 0)
                    .toFixed(2)
                return this.p__renderMoneyByCurrency(total, currency)
            }

            let total = 0

            if (total_metrics) {
                total = total_metrics[column_property]
            }

            if (
                [
                    'spend',
                    'cpc',
                    'cpm',
                    'cost_per_conversion',
                    'real_time_cost_per_conversion',
                    'total_onsite_shopping_value',
                    'cost_per_1000_reached'
                ].some((item) => column_property === item)
            ) {
                return this.p__renderMoneyByCurrency(total, currency)
            }

            if (['ctr'].some((item) => column_property === item)) {
                return `${this.p__formatNumberHasCommas(total)} %`
            }

            return this.p__formatNumberHasCommas(total)
        },

        m__handleToggleAutoRefresh() {
            if (this.m__auto_refresh_config.auto_refresh) {
                this.polling = setInterval(() => {
                    this.m__getReportCampaignsByAccount()
                }, this.m__auto_refresh_config.seconds * 1000)
            } else {
                clearInterval(this.polling)
            }
        },

        m__handleChangeSecondAutoRefresh() {
            clearInterval(this.polling)

            if (this.m__auto_refresh_config.auto_refresh) {
                this.polling = setInterval(() => {
                    this.m__getReportCampaignsByAccount()
                }, this.m__auto_refresh_config.seconds * 1000)
            }
        },

        m__handleChangeNeverstop() {
            if (this.m__auto_refresh_config.never_stop) {
                clearTimeout(this.timeout)
                this.timeout = null
                return
            }

            this.timeoutId = setTimeout(() => {
                this.m__auto_refresh_config.auto_refresh = false
                clearInterval(this.polling)
            }, this.m__auto_refresh_config.stop_after_minutes * 60 * 1000)
        },

        async m__handleSortChange({ column, prop, order }) {
            this.m__order_field = prop
            this.order_type = order === 'descending' ? 'DESC' : 'ASC'

            this.m__getReportCampaignsByAccount()
        }
    },

    beforeDestroy() {
        clearInterval(this.polling)
    }
}
